<template>
    <v-card v-if="articleDoc">
        <v-card-title class="text-h4 justify-center mb-5">{{ articleDoc.name }}</v-card-title>
        <v-card-subtitle class="text-h5 text-center font-weight-light">
            <v-row>
                <v-col cols="12" md="10" offset-md="1">
                    {{ articleDoc.summary }}
                    <v-divider class="mt-2"></v-divider>
                </v-col>
            </v-row>
        </v-card-subtitle>

        <markdown :value="articleDoc.content" :allow-editing="false" :article="true" :toc="true" />
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import markdown from '@/components/shared/markdown';

export default {
    name: 'project-doc-page',
    components: { markdown },
    computed: {
        ...mapGetters(['articleDoc']),
    },
};
</script>
